import React from 'react';
import PropTypes from 'prop-types';
import SvgComponent from '../../assets/svg/SvgComponent';
import Button from '../Button/Button';
import PostThumbnail from '../PostThumbnail/PostThumbnail';
import styles from './PostWidget.module.scss';

const PostWidget = ({
    slug,
    date,
    title,
    authorName,
    techno,
    technoMonogram,
    excerpt,
    thumbnailBackground,
    thumbnailTitle,
    thumbnailSubtitle,
}) => {
    return (
        <Button.Component
            type={Button.Type.ROUTER_LINK_AS_LINK}
            theme={Button.Theme.LINK_TRANSPARENT}
            to={`${slug}`}
            className={styles.link}
        >
            <article className={styles.container}>
                <header className={styles.header}>
                    <PostThumbnail
                        background={thumbnailBackground}
                        title={thumbnailTitle}
                        subtitle={thumbnailSubtitle}
                    />
                </header>
                <div className={styles.content}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.excerpt}>{excerpt}</p>
                </div>
                <footer className={styles.footer}>
                    <div className={styles.meta}>
                        <div>
                            <SvgComponent
                                name={technoMonogram}
                                className={styles.svg}
                            />
                        </div>
                        <div>
                            <p className={styles.metaHeaders}>Techno</p>
                            <p className={styles.metaContent}>{techno}</p>
                        </div>
                        <div>
                            <p className={styles.metaHeaders}>Auteur</p>
                            <p className={styles.metaContent}>{authorName}</p>
                        </div>
                        <div>
                            <p className={styles.metaHeaders}>Mis à jour le</p>
                            <p className={styles.metaContent}>{date}</p>
                        </div>
                    </div>
                </footer>
            </article>
        </Button.Component>
    );
};

PostWidget.propTypes = {
    date: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string,
    authorName: PropTypes.string,
    techno: PropTypes.string,
    technoMonogram: PropTypes.string,
    excerpt: PropTypes.string,
};

PostWidget.defaultProps = {
    date: new Date(),
    path: '',
    title: '',
    authorName: '',
    techno: '',
    technoMonogram: '',
    excerpt: '',
};

export default PostWidget;
