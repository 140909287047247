import React from 'react';
import Container from '../Container/Container';
import styles from './BlogHero.module.scss';

BlogHero.propTypes = {};

function BlogHero(props) {
    return (
        <Container className={styles.centerContainer}>
            <h1 className={styles.title}>Blog</h1>
        </Container>
    );
}

export default BlogHero;
