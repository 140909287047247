import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/Seo';
import Layout from '../components/Layout/Layout';
import BlogHero from '../components/BlogHero/BlogHero';
import BlogPostList from '../components/BlogPostList/BlogPostList';

import styles from './blog.module.scss';

const Blog = props => {
    const posts = props.data.allMarkdownRemark.edges;

    return (
        <Layout>
            <div className={styles.container}>
                <SEO title="blog" />
                <BlogHero />
                <BlogPostList posts={posts} />
            </div>
        </Layout>
    );
};

export default Blog;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/assets/articles/" } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 280)
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "DD MMMM YYYY")
                        path
                        title
                        authorName
                        authorUri
                        techno
                        technoMonogram
                        thumbnailBackground
                        thumbnailTitle
                        thumbnailSubtitle
                    }
                }
            }
        }
    }
`;
