import React from 'react';
import Container from '../Container/Container';
import PostWidget from '../PostWidget/PostWidget';
import styles from './BlogPostList.module.scss';

const BlogPostList = ({ posts }) => {
    return (
        <Container>
            <div className={styles.container}>
                {posts &&
                    posts.map(({ node: postNode }) => {
                        return (
                            <PostWidget
                                key={postNode.id}
                                slug={postNode.fields.slug}
                                date={postNode.frontmatter.date}
                                path={postNode.frontmatter.path}
                                title={postNode.frontmatter.title}
                                thumbnailBackground={
                                    postNode.frontmatter.thumbnailBackground
                                }
                                thumbnailTitle={
                                    postNode.frontmatter.thumbnailTitle
                                }
                                thumbnailSubtitle={
                                    postNode.frontmatter.thumbnailSubtitle
                                }
                                authorName={postNode.frontmatter.authorName}
                                authorUri={postNode.frontmatter.authorUri}
                                techno={postNode.frontmatter.techno}
                                technoMonogram={
                                    postNode.frontmatter.technoMonogram
                                }
                                excerpt={postNode.excerpt}
                            />
                        );
                    })}
            </div>
        </Container>
    );
};

export default BlogPostList;
